import 'slick-carousel-latest';

(function($){

    const MediaConsultaHome = {

        init: function() {
            
            jQuery('.home-main-slider').slick({
                autoplay: true,
                autoplaySpeed: 1000,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                speed: 2000
            });

            jQuery('.home-secondary-slider .wrapper').slick({
                autoplay: true,
                autoplaySpeed: 1000,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                speed: 2000
            });

        }

    }

    jQuery(document).ready(function(){

        MediaConsultaHome.init();

    });

})(jQuery);